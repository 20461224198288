import Swal from 'sweetalert2';

const sweetAlertSuccess = (icon: string, title: string, backdrop = true) => {
    // @ts-ignore
    return Swal.fire({
        position: 'center',
        icon: icon,
        title: title,
        showConfirmButton: false,
        timer: 1500,
        backdrop: backdrop,
    })
}

const sweetAlertCallback = (title: string,) => {
    return Swal.fire({
        title: title,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Salva`,
        denyButtonText: `Annulla`,
        cancelButtonText: 'Cancella'
    })
}

export {
    sweetAlertSuccess,
    sweetAlertCallback
}